import React, {useEffect, useState} from 'react';
import './App.css';
import {LoadingComponent} from "./components/Loading";
import { getGPUTier } from 'detect-gpu';

import {Mobile} from "./components/Mobile";
import {BrowserNotCompatible} from "./components/BrowserNotCompatible";


declare global {
  interface Window {
    unityInstance?: any;
    loading: boolean;
  }
}

export const env: string | 'production' | 'develop' | 'stage' =
    process.env.REACT_APP_BUILD_ENV || 'develop'; // change this to 'production' for debugging in prod mode

function App() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [progression, setProgression] = useState<number>(0);
    const [gpuTier, setGpuTier] = useState<any>(null);
    const [webglSupported, setWebglSupported] = useState<boolean>(true);

    let BASE_URL = '';
    switch (env) {
        case 'develop':
            BASE_URL = 'https://vispa-dev-app.s3.eu-central-1.amazonaws.com/WebGL';
            break;
        case 'stage':
            BASE_URL = 'https://vispa-test-app.s3.eu-central-1.amazonaws.com/WebGL';
            break;
        case 'production':
            BASE_URL = 'https://vispa-prod-app.s3.eu-central-1.amazonaws.com/WebGL';
            break;
        default:
            BASE_URL = 'https://vispa-dev-app.s3.eu-central-1.amazonaws.com/WebGL';
    }

    const pathVersion = window.location.pathname.split('/')[1] || 'Latest';
    let BUILD = `${BASE_URL}/${pathVersion}/`;

    useEffect(  () => {
        getGpuTier();
    }, [])

    const getGpuTier = async () => {
        const gpu = await getGPUTier();
        setGpuTier(gpu);
    }


    useEffect(() => {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

        if (!gl) {
            setWebglSupported(false);
        }
    }, []);


    useEffect(() => {

        const beforeUnloadHandler = (e: any) => {
                e.preventDefault();
                e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
        };

        window.addEventListener('beforeunload', beforeUnloadHandler);

        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [])


    const handleKeyDown = (event: any) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'p') event.preventDefault();
  }

  useEffect(() => {

    let srcs: string[] = [
      'aws/aws-sdk.min.js',
      'Youtube/youtube.js',
      'Filebrowser/filebrowsercontroller.js',
      'Filebrowser/filebrowsersfonts.js',
      'Filebrowser/filebrowserview.js',
      'AgoraWebSDK/libs/databuilder.js',
      'AgoraWebSDK/libs/clientmanager.js',
      'AgoraWebSDK/libs/wglwrapper.js',
      'AgoraWebSDK/libs/audioeffects.js',
      'AgoraWebSDK/libs/eventmanager.js',
      'AgoraWebSDK/libs/watermark.js',
      'AgoraWebSDK/libs/customvideo.js',
      'AgoraWebSDK/libs/agorachannel.js',
      'AgoraWebSDK/libs/multichannel.js',
      'AgoraWebSDK/libs/audiosystem.js',
      'AgoraWebSDK/libs/testing.js',
      'AgoraWebSDK/libs/agorautils.js',
      'AgoraWebSDK/libs/audiomixing.js',
      'AgoraWebSDK/agorartcenginev2.js',
      'AgoraWebSDK/vendor/materialize.min.js',
      'AgoraRTC_N.js',
      'AgoraWebSDK/vendor/jquery.min.js',
      'AgoraWebSDK/libs/engineglobals.js',
      'ReadyPlayerMe/readyplayerme.js',
      'Login/login.js',
      'Video/video.js'];
    let scripts: HTMLScriptElement[] = [];

    for(var i = 0; i < srcs.length; i++) {
        const script = document.createElement('script');
      
        script.src = BUILD + srcs[i];
      
      document.head.appendChild(script);
      scripts.push(script); 
    }

    return () => {
      for(var i = 0; i < scripts.length; i++)
      { 
        document.head.removeChild(scripts[i]);
      }
    }
  }, [BUILD]);
  
  useEffect(() => {

    if (gpuTier == null)
      return;
    if (gpuTier?.isMobile || !webglSupported)
      return;

    if (window.loading)
      return;

    window.loading = true;
    

    const buildUrl = BUILD + 'Build';
    const config = {
      dataUrl: buildUrl + '/VISPA.data',
      frameworkUrl: buildUrl + '/VISPA.framework.js',
      codeUrl: buildUrl + '/VISPA.wasm',
      streamingAssetsUrl: BUILD + 'StreamingAssets',
      companyName: 'SpaceOne',
      productName: 'VISPA_',
      productVersion: '1.1.1 (4910)',
    };

    const canvas = document.querySelector('#unity-canvas');
      
    const script = document.createElement('script');
    script.src = buildUrl + '/VISPA.loader.js';

    script.onload = () => {
        // @ts-ignore
      createUnityInstance(canvas, config, (p) => {
          setProgression(p);
      })
      .then((unityInstance2: any) => {
          setIsLoaded(true);
      window.unityInstance = unityInstance2;
      })
      .catch((message: any) => {
      alert(message);
      });
    };
    document.body.appendChild(script);





    return () => {
      // document.body.removeChild(script);
    }
  }, [BUILD, gpuTier, webglSupported]);

  if (gpuTier?.isMobile) return <Mobile />;
  if (!webglSupported) return <BrowserNotCompatible />

  return (
    <div className="App">
      {!isLoaded && (
          <LoadingComponent props={{ loadingProgression: progression }} />
      )}
      <div style={{ display: !isLoaded ? 'none' : ''}} className='webgl-container'>
        <div style={{display:'flex'}} id='unity-container' className='unity-desktop'>
          <canvas id='unity-canvas' style={{ width: '100vw', height: '100vh' }} ></canvas>
        </div>
      </div>
    </div>
  );
}
export default App;
