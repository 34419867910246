import logo from "../assets/images/VispaFun_Logo.svg";
import sadLogo from "../assets/images/Sad_MobileDeivce.png";
import React from "react";

export function BrowserNotCompatible() {
    return (
        <div
            className='webgl-container'
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <img
                style={{
                    margin: '0 5rem',
                    width: 200,
                    height: 200,
                }}
                src={logo}
                alt='logo'
            />
            <img
                style={{
                    marginTop: 60,
                    marginBottom: 0,
                    height: 116,
                }}
                src={sadLogo}
                alt='unhappy'
            />
            <p
                style={{
                    textAlign: 'center',
                    fontSize: 24,
                    lineHeight: 1.4,
                    maxWidth: 500,
                }}
            >
                Sorry, your browser doesn't support Webgl.
            </p>
        </div>
    )
}
