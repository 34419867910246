import { Progress } from 'antd';

import logo from './../assets/images/VispaFun_Logo.svg';

const loadingScreenTexts = [
    'Get ready for a fun challenge...',
    'Close down your email inbox...',
    'Prepare for epic team play...',
    'Strap in for a synergy adventure...',
    'Get set for laughter and teamwork...',
    'Buckle up and enjoy the ride...',
];

const didYouKnowFacts = [
    'Successful teamwork requires trust and open communication.',
    'Teamwork is like a puzzle; every piece matters',
    'Celebrate small wins to build a culture of success',
    'Share ideas freely; innovation thrives in a supportive team',
    'Effective teamwork is the key to unlocking shared goals',
    'Team synergy is your superpower; use it wisely!',
];

const fact =
    didYouKnowFacts[Math.floor(Math.random() * didYouKnowFacts.length)];

function getTextForPercent(percent: number) {
    if (percent > 90) return loadingScreenTexts[5];
    if (percent > 75) return loadingScreenTexts[4];
    if (percent > 50) return loadingScreenTexts[3];
    if (percent > 35) return loadingScreenTexts[2];
    if (percent > 15) return loadingScreenTexts[1];
    return loadingScreenTexts[0];
}

export function LoadingComponent({ props }: any) {
    const percent = Math.round(props.loadingProgression * 100);

    return (
        <div>
            <div className='loading'>
                <img
                    style={{
                        margin: '0 5rem 60px',
                        width: 200,
                        height: 200,
                    }}
                    src={logo}
                    alt='logo'
                />
                <p
                    style={{
                        fontSize: 20,
                        fontWeight: 700,
                        marginBottom: 15,
                        lineHeight: 1.4,
                    }}
                >
                    {getTextForPercent(percent)} {percent}%
                </p>
                <Progress
                    style={{ maxWidth: 500 }}
                    showInfo={false}
                    strokeColor={{
                        '0%': '#606ee0',
                        '100%': '#a356d2',
                    }}
                    percent={Math.round(props.loadingProgression * 100)}
                />
                <p
                    style={{
                        marginTop: 50,
                        fontSize: 18,
                        fontWeight: 600,
                        maxWidth: 500,
                        lineHeight: 1.4,
                    }}
                >
                    {fact}
                </p>
            </div>
        </div>
    );
}
